// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-boek-js": () => import("./../../../src/pages/boek.js" /* webpackChunkName: "component---src-pages-boek-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kijk-js": () => import("./../../../src/pages/kijk.js" /* webpackChunkName: "component---src-pages-kijk-js" */),
  "component---src-pages-luister-js": () => import("./../../../src/pages/luister.js" /* webpackChunkName: "component---src-pages-luister-js" */),
  "component---src-pages-wie-js": () => import("./../../../src/pages/wie.js" /* webpackChunkName: "component---src-pages-wie-js" */),
  "component---src-pages-zangles-js": () => import("./../../../src/pages/zangles.js" /* webpackChunkName: "component---src-pages-zangles-js" */)
}

